<template>
  <div>
    <h2>Rich Text Editor Examples</h2>
    <ul>
      <li>
        <h3>Example 1:</h3>
        <div class="mb-2">Regular / Default size Toolbar.</div>
        <RichTextEditor v-model="editor_content" />
        <div class="my-3">
          <span class="mb-3">- Editor content in <u>String</u> format:</span>
          <p>{{ editor_content }}</p>
        </div>
        <div class="my-3">
          <span class="mb-3">- Editor content rendered in <u>HTML</u>:</span>
          <div v-html="editor_content"></div>
        </div>
      </li>
      <li>
        <h3 class="mt-3">Example 2:</h3>
        <div class="mb-2">Basic size Toolbar.</div>
        <RichTextEditor toolbar="basic" />
      </li>
      <li>
        <h3 class="mt-3">Example 3:</h3>
        <div class="mb-2">Full size Toolbar.</div>
        <RichTextEditor toolbar="full" />
      </li>
    </ul>
  </div>
</template>

<script>
import RichTextEditor from '@/components/ui/RichTextEditor.vue';

export default {
  name: 'RichTextEditorExamples',
  components: { RichTextEditor },
  data() {
    return {
      editor_content: 'Type here ...',
    };
  },
};

</script>

<style lang="stylus">

</style>
